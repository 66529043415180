// import Vue from 'vue'
import Vuex from 'vuex'



// import { createStore } from 'vuex'
import { FeathersVuex } from '@/api/feathers-client'

// import createPersistedState from 'vuex-persistedstate'
import data from './data'
import user from './user'
import ui from './ui'
import auth from './store.auth'
// import { FeathersVuex } from '@/api/feathers-client'

// Vue.create
// Vue.use(Vuex);
// Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  '../services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)


const store = new Vuex.Store({
  state: {
    version: '0.1'
  },
  mutations: {

  },
  actions: {

  },
  modules: {
    data: data,
    user: user,
    ui: ui
  },
  plugins: [
    // createPersistedState({
    //   storage: window.localStorage
    // }),
    ...servicePlugins,
    auth
  ]
});

export { store, FeathersVuex }


// export default createStore({
//   state: {
//     version: '0.1'
//   },
//   mutations: {

//   },
//   actions: {

//   },
//   modules: {
//     data: data,
//     user: user,
//     ui: ui
//   },
//   plugins: [
//     // createPersistedState({
//     //   storage: window.localStorage
//     // }),
//     ...servicePlugins,
//     auth
//   ]
// })
