<template>
  <div class="row">
    <div class="col-me-12 rater-content">
      <button type="button" class="btn btn-outline-primary miss">MISS</button>
      <div class="rater-buttons">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio1"
            value="option1"
          />
          <label>1</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio2"
            value="option2"
          />
          <label>2</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="option3"
            disabled
          />
          <label>3</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="option3"
            disabled
          />
          <label>4</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="inlineRadioOptions"
            id="inlineRadio3"
            value="option3"
            disabled
          />
          <label>5</label>
        </div>
      </div>
      <button type="button" class="btn btn-outline-primary hit">HIT</button>
    </div>
    <div class="comments">
      <div class="card bg-light text-dark">
        <div class="card-body text-center">
          <div class="row">
            <div class="col-8 rater-comment">
              Ratings & Comments is completely anonymous to the artist. Genuine
              feedback is appreciated!
            </div>
            <div class="col-4">
              <button type="button" class="btn btn-primary btn-comment">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="461 796 100 100"
                >
                  <defs>
                    <svg:style type="text/css">
                      <![CDATA[.cls-1 {
                        clip-path: url(#clip-submit-icon);
                      }
                      .cls-2 {
                        fill: #fff;
                      }]]>
                    </svg:style>
                    <clipPath id="clip-submit-icon">
                      <path d="M461 796h100v100H461z"></path>
                    </clipPath>
                  </defs>
                  <g id="submit-icon" class="cls-1">
                    <g id="XMLID_95_">
                      <path
                        id="XMLID_96_"
                        class="cls-2"
                        d="M553.077 802.958a8.394 8.394 0 0 0-9.28-1.856l-73.072 30.066a8.537 8.537 0 0 0 .44 15.96l24.983 8.538a3.322 3.322 0 0 0 3.247-.61l31.57-26.784a3.346 3.346 0 1 0-4.328-5.1l-30.12 25.553-23.178-7.926a1.8 1.8 0 0 1-1.264-1.688 1.753 1.753 0 0 1 1.148-1.765l73.07-30.084a1.866 1.866 0 0 1 2.438 2.43l-30.066 73.07a1.8 1.8 0 0 1-1.766 1.15 1.766 1.766 0 0 1-1.687-1.265l-6.813-19.95a3.346 3.346 0 1 0-6.33 2.17l6.813 19.95a8.485 8.485 0 0 0 7.81 5.77h.28a8.5 8.5 0 0 0 7.89-5.29l30.062-73.066a8.354 8.354 0 0 0-1.85-9.274zm0 0"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RaterButton",
};
</script>

<style scoped>
.rater-buttons {
  padding: 10px;
  margin: auto;
}

.btn-outline-primary {
  border-color: #f3225d;
}

.comments {
  margin-top: 10px;
}

.rater-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 100px 0 100px;
}

.miss,
.hit {
  border-radius: 250px;
  border-color: #fb007c;
}

.hit {
  background-color: #fb007c;
  color: #fff;
}

.miss {
  color: #fb007c;
}

.rater-comment {
  display: block;
  text-align: left;
}

.btn-comment {
  background-color: #46bae9;
  border-color: #46bae9;
}

label {
  margin: 18px 0 0 -22px;
  position: absolute;
  color: #b7b7b7;
}

@media only screen and (min-width: 320px) {
  .miss,
  .hit {
    min-width: 60px;
    max-height: 32px;
    font-size: 12px;
  }

  .rater-comment {
    font-size: 12px;
  }

  .form-check-inline {
    margin-right: 0px;
    margin-top: -5px;
  }

  svg {
    width: 27px;
  }

  .rater-content {
    padding: 0 15px 0 15px;
  }
}

@media only screen and (min-width: 375px) {
  .rater-content {
    padding: 0 40px 0 40px;
  }
}

@media only screen and (min-width: 425px) {
  .rater-content {
    padding: 0 50px 0 50px;
  }
}

@media only screen and (min-width: 768px) {
  .rater-content {
    padding: 0 70px 0 70px;
  }
}

@media only screen and (min-width: 1024px) {
  .rater-content {
    padding: 0 150px 0 150px;
  }
}

@media only screen and (min-width: 1440px) {
  .rater-content {
    padding: 0 150px 0 150px;
  }
}
</style>