const data = {
  namespaced: true,
  state: {
    dashboardDropdown: [],
    gallery: [{}],
    // temporary community data
    communityData: [
      {
        id: 0,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        verified:
          "https://d29b09clwjrqb.cloudfront.net/img/verified-badge-02.png",
        artistName: "Ashley Newman",
        countryUrl: "https://d29b09clwjrqb.cloudfront.net/img/flags/us.png",
        date: "Mar. 09, 2022",
      },
      {
        id: 1,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        verified:
          "https://d29b09clwjrqb.cloudfront.net/img/verified-badge-02.png",
        artistName: "Ashley Newman",
        countryUrl: "https://d29b09clwjrqb.cloudfront.net/img/flags/us.png",
        date: "Mar. 09, 2022",
      },
    ],
    // leaderboard list
    leaderBoardList: [
      {
        id: 0,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        artistName: "Ashley Newman",
        address: "Memphis, Tennessee",
        rank: 1,
      },
      {
        id: 1,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        artistName: "Ashley Newman",
        address: "Memphis, Tennessee",
        rank: 2,
      },
      {
        id: 2,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        artistName: "Ashley Newman",
        address: "",
        rank: 3,
      },
      {
        id: 3,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        artistName: "Ashley Newman",
        address: "",
        rank: 4,
      },
      {
        id: 4,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        artistName: "Ashley Newman",
        address: "",
        rank: 5,
      },
    ],
    //artist list
    artistrList: [
      {
        id: 0,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        recordCompany: "Coca Vango",
        genre: "Hip-Hop",
      },
      {
        id: 2,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        recordCompany: "Republic Records",
        genre: "",
      },
      {
        id: 3,
        imgUrl:
          "https://breakinghits.s3.amazonaws.com/profile/pxtcm-7580-1643268096-medium.jpg",
        recordCompany: "LAmp Group",
        genre: "",
      },
    ],
    // Country temporary data
    countryData: [
      {
        value: "en",
        name: "United States of America",
        icon: "https://www.breakinghits.com/img/flags/us.png",
      },
      {
        value: "za",
        name: "South Africa",
        icon: "https://www.breakinghits.com/img/flags/za.png",
      },
    ],
    countryState: ["Alabama", "Alaska"],
    countryCity: ["Abanda", "Abevail"],
    countryLanguage: [
      {
        value: "en",
        name: "English",
        icon: "https://www.breakinghits.com/img/flags/us.png",
      },
      {
        value: "es",
        name: "Spanish",
        icon: "https://www.breakinghits.com/img/flags/es.png",
      },
      {
        value: "kr",
        name: "Korean",
        icon: "https://www.breakinghits.com/img/flags/kr.png",
      },
    ],
    languageOption: "en",
  },
  mutations: {
    setDashboardDropdown(state, dashboardDropdown) {
      state.dashboardDropdown = dashboardDropdown;
    },
    setGallery(state, gallery) {
      state.gallery = gallery;
    },
    setCommunityData(state, communityData) {
      state.communityData = communityData;
    },
    setLeaderBoardList(state, leaderBoardList) {
      state.avatarList = leaderBoardList;
    },
    setArtistrList(state, artistrList) {
      state.artistrList = artistrList;
    },
    setLanguageOption(state,languageOption) {
      state.languageOption = languageOption;
    }
  },
  getters: {
    getDashboardDropdown: (state) => state.dashboardDropdown,
    getGallery: (state) => state.gallery,
    getCommunityData: (state) => state.communityData,
    getLeaderBoardList: (state) => state.leaderBoardList,
    getArtistrList: (state) => state.artistrList,
    getCountryData: (state) => state.countryData,
    getCountryState: (state) => state.countryState,
    getCountryCity: (state) => state.countryCity,
    getCountryLanguage: (state) => state.countryLanguage,
    getLanguageOption: (state) => state.languageOption
  },
};

export default data;
