import { createRouter, createWebHistory } from "vue-router";
import { feathersClient } from '@/api/feathers-client';
import HomeView from "../views/HomeView.vue";
import SignupSection from "../views/Signup.vue";
import LoginSection from "../views/Login.vue";
import ForgotPassowrd from "../views/ForgotPassword.vue";
import DashboardHome from "../views/dashboard/DashboardHome.vue";
import CommunitySection from "../views/dashboard/Community.vue";
import SearchArtist from "../views/dashboard/SearchArtist.vue";
import RequestArtistAccount from "../components/Settings/RequestArtistAccount.vue";
import MessengerSection from "../views/dashboard/Messenger.vue";
import ProfileSection from "../views/dashboard/Profile.vue";
import ProfileSetting from "../views/dashboard/ProfileSettings.vue";
import InviteFriends from "../components/Settings/InviteFriends.vue";
import ChangePassword from "../components/Settings/ChangePassword.vue";
import CreateEmbeded from "../components/Settings/CreateEmbeded.vue";
import ManageProfile from "../components/Settings/ManageProfile";
import SavedArtist from "../views/dashboard/Saved.vue";
import ReportPage from "../views/dashboard/Report.vue";
import ResetPassword from "../views/ResetPassword.vue";
import { store } from "@/store";

function guardMyroute(to, from, next) {
  if (store.getters["user/getIsLogin"]) {
    store.commit("ui/setPageLoading", false);
    next();
  } else if (localStorage.getItem('feathers-jwt')) {
    feathersClient.reAuthenticate().then((result) => {
      store.commit("user/setIsLogin", true);
      store.commit("ui/setShowLangOption", false);
      store.commit("ui/setPageLoading", false);
      store.commit("ui/setDashboardModalCount", 1);
      store.commit(
        "user/setUserId",
        result.user.id
      );
      !store.getters["ui/getHideFooter"]
        ? store.commit("ui/setHideFooter")
        : "";
      next();
    }).catch(() => {
      store.commit("ui/setPageLoading", false);
      next("/login");
    });
  } else {
    store.commit("ui/setPageLoading", false);
    next("/login");
  }
}

function defaultGuardRoute(to, from, next) {
  if(store.getters["user/getIsLogin"]) {
    store.commit("ui/setPageLoading", false);
    next("/dashboard");
  } else if(localStorage.getItem('feathers-jwt')) {
    feathersClient.reAuthenticate().then((result) => {
      store.commit("user/setIsLogin", true);
      store.commit("ui/setShowLangOption", false);
      store.commit("ui/setPageLoading", false);
      store.commit("ui/setDashboardModalCount", 1);
      store.commit(
        "user/setUserId",
        result.user.id
      );
      !store.getters["ui/getHideFooter"]
        ? store.commit("ui/setHideFooter")
        : "";
      next("/dashboard");
    }).catch(() => {
      store.commit("ui/setPageLoading", false);
      next();
    });
  } else {
    store.commit("ui/setPageLoading", false);
    next();
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: defaultGuardRoute,
    component: HomeView,
  },
  {
    path: "/signup",
    name: "Signup",
    beforeEnter: defaultGuardRoute,
    component: SignupSection,
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: defaultGuardRoute,
    component: LoginSection,
    // beforeEach: (to, from, next) => {
    //   if (store.getters["user/getIsLogin" == true]) {
    //     next({ name: "Dashboard" });
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    beforeEnter: guardMyroute,
    component: DashboardHome,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    beforeEnter: defaultGuardRoute,
    component: ForgotPassowrd,
  },
  {
    path: "/reset-password/:code",
    name: "Reset Password",
    beforeEnter: defaultGuardRoute,
    component: ResetPassword,
  },
  {
    path: "/community",
    name: "Community",
    beforeEnter: guardMyroute,
    component: CommunitySection,
  },
  {
    path: "/messages",
    name: "Messages",
    beforeEnter: guardMyroute,
    component: MessengerSection,
  },
  {
    path: "/profile",
    name: "Profile",
    beforeEnter: guardMyroute,
    component: ProfileSection,
  },
  {
    path: "/search/artist",
    name: "Search Artist",
    component: SearchArtist,
  },
  {
    path: "/saved",
    name: "Saved Artist",
    beforeEnter: guardMyroute,
    component: SavedArtist
  },
  {
    path: "/report",
    name: "Report",
    beforeEnter: guardMyroute,
    component: ReportPage
  },
  {
    path: "/settings",
    name: "Profile Setting",
    beforeEnter: guardMyroute,
    component: ProfileSetting,
    children: [
      { path: "", redirect: "/settings/request-artist-account" },
      { path: "request-artist-account", component: RequestArtistAccount },
      { path: "invite", component: InviteFriends },
      { path: "change-password", component: ChangePassword },
      { path: "create-embeded", component: CreateEmbeded },
      { path: "basic", component: ManageProfile },
    ],
  },
  {
    path: "/:user",
    name: "Profile",
    beforeEnter: guardMyroute,
    component: ProfileSection,
    children: [
      { path: "", component: () => import('../views/profile/ProfileDashboardView.vue') },
      { path: "songs", component: () => import("../views/profile/SongsView.vue") },
      { path: "spotlight", component: () => import("../views/profile/SpotlightView.vue") },
      { path: "hitfluencers", component: () => import("../views/profile/HitfluencersView.vue") },
      { path: "following", component: () => import("../views/profile/FollowingView.vue") },
      { path: "achievement-dashboard", component: () => import("../views/profile/AchievementDashboardView.vue") }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
