<template>
  <div id="jombotron">
    <div class="bg-dark">
      <div class="container-fluid text-center" id="jombotron-custom">
        <img class="img-fluid" src="../assets/image/Amplified-Authentic-Opinions.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JumbotronSection",
};
</script>

<style scoped>
#jombotron {
    margin: 52px 0 0 0;
}

#jombotron-custom {
     background-color: black;
}
</style>>