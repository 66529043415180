<template>
  <Transition name="modal">
    <div v-if="show" class="modal-mask">
      <div class="modal-postion">
        <div class="modal-wrapper">
          <div class="upper">
            <div id="modal-upper-right">
              <button
                class="btn btn-light"
                id="close-btn"
                @click="$emit('close')"
              >
                <span id="close-btn-text">x</span>
              </button>
            </div>
            <div class="row modal-upper">
              <div class="col d-flex">
                <span id="modal-left-img"
                  ><img
                    src="https://www.breakinghits.com/img/befirst-icon.png"
                    alt=""
                /></span>
                <span id="modal-left-text"><h5>Create Page Username</h5></span>
              </div>
            </div>
          </div>
          <div class="main-wrapper">
            <span id="smile"
              ><font-awesome-icon :icon="['fas', 'smile']"
            /></span>
            <div class="modal-container">
              <div class="row">
                <div class="col-md-2">
                  <img
                    src="https://breakinghits.s3.amazonaws.com/profile/4iwb2-6742-1617185917-thumb.jpg"
                    width="80"
                    class="rounded-circle img-fluid"
                  />
                </div>
                <div class="col-md-10">
                  <div class="avatar-detail">
                    <h3 class="mb-0">User Name</h3>
                    <span id="url-sample"
                      >https://www.breakinghits.com/name</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-text">
              <p>
                It's easier for people to find you in search when it has a
                unique username. Pages with a username have an easy to share
                custom personal invite URL that let people quickly visit and
                engage them.
              </p>
            </div>
            <div id="edit-user">
              <router-link to="/settings/basic">Click here to edit your username</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: "CreatePageModal",
  props: {
    show: Boolean,
  },
};
</script>

<style scoped>
.modal-mask {
  padding: 0 30%;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  margin: auto;
  max-width: 700px !important;
}

.modal-container {
  width: 100%;
  margin: 0px auto;
  padding: 3px 11px;
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.upperHeader {
  margin: auto;
}

#close-btn {
  position: relative;
  float: right;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

#close-btn-text {
  display: block;
  color: red;
  font-weight: bold;
  font-size: 20px;
  margin: -9px 0 0 -4px;
}

.main-wrapper {
  padding: 20px;
  background: linear-gradient(17deg, #fa600a 0%, #ff007e 100%);
  border-radius: 10px;
}

#modal-left-img {
  background-color: #fff;
  padding: 5px;
  border-radius: 50px;
  position: absolute;
  margin-left: -50px;
  margin-top: -3px;
}
#modal-left-img img {
  max-width: 60px;
}

#modal-left-text {
  color: #fff;
  margin-left: 28px;
  font-weight: 400;
}

.modal-upper {
  padding-bottom: 10px;
}

#modal-upper-right {
  padding: 0px;
}

.avatar-detail {
  color: #fff;
  margin-left: 10px;
}

.avatar-detail h3 {
  font-weight: 400;
}

#url-sample {
  display: block;
  margin: -6px 0 0 4px;
  font-size: 15px;
}

#smile {
  color: #fff;
  font-size: 22px;
  float: right;
  margin: -15px -10px 0 0;
}

.modal-text {
  padding: 0 10px;
  color: #fff;
}
.modal-text p {
  text-align: justify;
}

#edit-user {
  text-align: center;
}

#edit-user a {
  color: #fff;
  text-decoration: none;
}

#edit-user a:hover {
  text-decoration: underline;
}

.modal-postion {
  margin-top: 110px;
}

@media only screen and (min-width: 320px) {
  .modal-mask {
    padding: 0px;
  }

  .modal-wrapper {
    padding: 0 4%;
  }

  .avatar-detail {
    margin-left: 0px;
    margin-bottom: 10px;
  }

  #url-sample {
    margin-left: 0px;
  }

  .modal-container {
    padding: 0px;
  }
}
</style>