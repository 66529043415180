<template>
  <div id="artist-account">
    <div class="row">
      <div class="col-md-12">
        <p>
          {{ $t("dashboard.settings.artist-account") }}
        </p>
        <form>
          <div class="mb-3">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Example textarea</label
            >
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label"
              >Create your Personal Invite URL</label
            >
            <input
              type="text"
              class="form-control"
              id="exampleFormControlInput1"
            />
            <div
              class="form-text text-muted"
              style="margin-top: 5px; font-size: 8pt"
            >
              Note: Special character is not allowed.
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="mb-3 form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >I have completed my basic profile and social links to be used
                  for review.</label
                >
              </div>
            </div>
            <div class="col-sm-6">
              <button class="btn btn-danger" id="submit-btn" type="button">
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsRequestAccount",
  mounted() {
    this.tempInit();
  },
  methods: {
    tempInit() {
      if (this.$store.getters["ui/getShowLangOption"])
        this.$store.commit("ui/setShowLangOption");
      
      console.log(this.$store.getters["ui/getShowLangOption"])
    },
  },
};
</script>

<style scoped>
#artist-account {
  background-color: #ffffff;
  padding: 0 0 5% 0;
}

#submit-btn {
  float: right;
}

form {
  padding: 0 10px 30px 10px;
}
</style>