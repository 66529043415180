import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store, FeathersVuex  } from './store'
import VueI18n from './i18n'
import GAuth from 'vue3-google-oauth2'

import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

// import FeathersVuex from '@feathersjs/vuex/dist';


import 'bootstrap/dist/css/bootstrap.min.css'
import './style.scss'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { 
  faFacebook, 
  faGooglePlus,
  faSpotify,
  faWikipediaW,
  faTwitter,
  faLinkedin,
  faDeezer,
  faItunes,
  faGooglePlay,
  faApple
} from '@fortawesome/free-brands-svg-icons'
import { 
  faPhone, 
  faSearch, 
  faPlay,
  faPause,
  faChevronDown,
  faListUl,
  faPlusCircle,
  faCommentDots,
  faComments,
  faCloudUpload,
  faTicket,
  faSmile,
  faListOl,
  faCommenting,
  faAlignJustify,
  faComment,
  faUser,
  faKey,
  faEye,
  faEyeSlash,
  faCircleUser,
  faLock,
  faArrowLeft,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faPhone, 
  faSearch, 
  faPlay, 
  faPause,
  faFacebook, 
  faGooglePlus,
  faChevronDown,
  faListUl,
  faPlusCircle,
  faCommentDots,
  faCloudUpload,
  faTicket,
  faSpotify,
  faWikipediaW,
  faTwitter,
  faLinkedin,
  faDeezer,
  faItunes,
  faSmile,
  faListOl,
  faCommenting,
  faAlignJustify,
  faComments,
  faComment,
  faUser,
  faKey,
  faEye,
  faEyeSlash,
  faCircleUser,
  faLock,
  faGooglePlay,
  faApple,
  faArrowLeft,
  faEnvelope
);  

const gauthOption = {
  clientId: '426128200929-38kteeku7sradapffb1tal3u8als043o.apps.googleusercontent.com',
  scope: 'email',
  prompt: 'select_account',
  fetch_basic_profile: true
}

createApp(App)
.use(store)
.use(FeathersVuex)
.use(router)
.use(VueI18n)
.use(GAuth, gauthOption)
.component('font-awesome-icon', FontAwesomeIcon)
.component('Datepicker', Datepicker)
.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})
.mount('#app')

import 'bootstrap/dist/js/bootstrap.js'