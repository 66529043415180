<template>
  <div id="dashboard">
    <div class="row text-center">
      <div class="col-md-12" id="title">
        <DropdownButton
          :title="$t('dashboard.discover.title')"
          :btnName="$t('dashboard.discover.button-name')"
          :dropDownData="tempDropdown"
          @btnOption="btnOption"
        />
      </div>
      <div id="gallery-section">
        <GallerySection />
      </div>
      <div>
        <Teleport to="body">
          <modal :show="showModal" @close="showModal = false" />
        </Teleport>
      </div>
      
    </div>
  </div>
</template>

<script>
import GallerySection from "@/components/Gallery.vue";
import DropdownButton from "@/components/subcomponents/DropdownButton.vue";
import Modal from "@/components/modals/Modal.vue";
// import CommonModal from "@/components/modals/CommonModal.vue";

export default {
  name: "DashboardHome",
  components: {
    GallerySection,
    DropdownButton,
    Modal,
    // CommonModal,
  },
  data() {
    return {
      title: "Discover Trending",
      btnName: "Artist",
      tempDropdown: [
        "Featured",
        "Pop",
        "Dance",
        "EDM",
        "Rock",
        "Coutry",
        "Folk",
        "Hip-Hop",
        "Rap",
        "R&B",
        "Funk/Soul",
        "Underground",
        "Latin",
        "DJ Mix",
        "Producer Tracks",
        "Music Networks",
      ],
      showModal: false,
    };
  },
  methods: {
    btnOption(event) {
      console.log(event);
    },

    showGenreViews() {
      if (!this.$store.getters["ui/getShowGenreViews"])
        this.$store.commit("ui/setShowGenreViews");
    },

    modal() {
      console.log(this.$store.getters["ui/getDashboardModalCount"]);

      if (this.$store.getters["ui/getDashboardModalCount"] === 1) {
        this.showModal = true;
        this.$store.commit("ui/setDashboardModalCount", 0);
      }
    },
    feedbackModal() {
      console.log("Dashboard feedback");
    },

    async test() {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6ImFjY2VzcyJ9.eyJpYXQiOjE2NTE3MjIzMDAsImV4cCI6MTk2NzI5MTU2MCwiYXVkIjoiaHR0cHM6Ly93d3cuYnJlYWtpbmdoaXRzLmNvbSIsImlzcyI6ImZlYXRoZXJzIiwic3ViIjoiNzc0OCIsImp0aSI6IjI0NGFlZGIwLTk2YTUtNGJmYi1iOTQ4LTA2NTE5YTg2M2E3ZiJ9.C5SS40Sk7Ir1zgidmBvBvPzMkdh4-aLNdJMkzFVaVno"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const res = await fetch(
        "https://apiv3.breakinghits.com/user-musics/7748",
        requestOptions
      );
      const data = await res.json();
      console.log(data);
    },
    loginTest() {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        strategy: "local",
        username: "johnnypaid12@gmail.com",
        password: "sigbin2022",
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://apiv3.breakinghits.com/authentication", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
  },
  mounted() {
    // this.test();
    this.$i18n.locale = this.$store.getters["data/getLanguageOption"].value;

    this.$store.getters["ui/getShowFeedbackModal"]
      ? this.$store.commit("ui/setShowFeedbackModal")
      : "";
    // console.log(this.$store.getters["ui/getShowFeedbackModal"]);
    // console.log(this.$store.getters["ui/getHideFooter"]);

    console.log(this.$store.getters["user/getUserId"])

    this.showGenreViews();
    this.modal();
  },
};
</script>

<style scoped>
#dashboard {
  background-color: #fff;
}

#title {
  margin-top: 80px;
  padding: 10px;
}

#gallery-section {
  margin-top: -39px;
}

@media only screen and (min-width: 320px) {
  #title {
    margin-top: 71px;
  }

  #gallery-section {
    margin-top: -10px;
  }
}
</style>