<template>
  <div id="dropdown-button">
    <span class="dropdown-title">{{ title }}</span>
    <div class="dropdown">
      <button
        class="btn btn-secondary dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ artistOption }}
      </button>
      <ul class="dropdown-menu">
        <li
          v-for="(items, index) in dropdownItems"
          :key="index"
          @click="searchGenre(items)"
        >
          <a class="dropdown-item">{{ items }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "DropdownButton",
  props: {
    title: String,
    btnName: String,
    dropDownData: Array
  },
  data() {
    return {
      artistOption: this.btnName,
      dropdownItems: this.dropDownData
    };
  },
  mounted() {
    this.setDropdownData();
  },
  methods: {
    setDropdownData() {
      // change local data to api data
    },

    searchGenre(genre) {
      this.artistOption = genre;
      this.$emit("btnOption", genre);
    },
  },
};
</script>

<style scoped>
#dropdown-button {
  display: flex;
  justify-content: center;
}
.dropdown-title {
  color: #f3225d;
  font-weight: 400;
  font-size: 23px;
  padding-right: 15px;
}

.btn-secondary {
  min-width: 100px; 
  background-color: #f3225d;
  border-color: transparent;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
</style>