import { feathersClient, makeServicePlugin, BaseModel } from "@/api/feathers-client"; 

class VWFeaturedMusics extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'VWFeaturedMusics'
  // Define default properties here
  static instanceDefaults() {
    return {}
  }
}

const servicePath = 'vw-featured-musics'
const servicePlugin = makeServicePlugin({
  Model: VWFeaturedMusics,
  service: feathersClient.service(servicePath),
  servicePath: servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [ async context => {
      (context.result.data || context.result).map(item => {
        item.artistName = decodeURI(`${item.user.result.fname} ${item.user.result.lname}`);
      });
      return context;
    } ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin