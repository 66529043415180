import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import {iff, discard} from 'feathers-hooks-common';
import feathersVuex from '@feathersjs/vuex/dist';

const socket = io('https://apiv3.breakinghits.com', {transports: [/*'websocket',*/ 'polling']})

const feathersClient = feathers()
  .configure(socketio(socket, { timeout: 30000 }))
  .configure(auth({storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  })

const { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex } =
  feathersVuex(feathersClient, {
    idField: 'id'
  })

export { feathersClient }
export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }

// export default feathersClient

// // Setting up feathers-vuex
// const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
//   feathersClient,
//   {
//     serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
//     idField: '_id', // Must match the id field in your database table/collection
//     whitelist: ['$regex', '$options']
//   }
// )

// export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }

