<template>
  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <span class="lang-icon"><img :src="btnIcon" alt="" /></span>{{ btnName }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li v-for="(lang, i) in langs" :key="`Lang${i}`">
        <a type="btn" class="dropdown-item" @click.stop="getLang(lang)"
          ><img :src="lang.icon" alt="" /> {{ lang.name }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LocalChanger",
  data() {
    return {
      langs: [],
      value: "",
      btnName: "English",
      btnIcon: "",
    };
  },
  methods: {
    getOption(e) {
      console.log(e.target.value);
      this.$store.commit("data/setLanguageOption", e.target.value);
      console.log(this.$store.getters["data/getLanguageOption"]);
    },
    getLang(option) {
      this.btnName = option.name;
      this.btnIcon = option.icon;
      this.$store.commit("data/setLanguageOption", option);
      this.$i18n.locale = option.value;
    },
  },
  mounted() {
    this.value = this.$store.getters["data/getLanguageOption"];
    console.log(this.$store.getters["data/getCountryLanguage"]);
    this.langs = this.$store.getters["data/getCountryLanguage"];
    console.log(this.$store.getters["data/getLanguageOption"]);

    this.btnName = this.value.name;
    this.btnIcon = this.value.icon;
    this.$i18n.locale = this.value.value;
  },
};
</script>
<style scoped>
.lang-icon {
  padding: 5px;
}

.btn-secondary:hover,
.btn-secondary {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.dropdown-menu.show {
  background-color: #222222;
}

.dropdown-item {
  color: #fff;
}

ul {
  text-align: center;
}

li {
  display: block;
}

/* .btn:focus,
.btn:active {
  background-color: black;
  border-color: black;
} */
</style>