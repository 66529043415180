<template>
  <div id="DownloadApp">
    <div class="container" id="downloadAppContent">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6" id="bh-logo">
          <div class="breakinghits-svgLogo">
            <svg
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 960 330.3"
            >
              <svg:style type="text/css">
                <![CDATA[.st0{fill:#ff0031}.st1{fill:#ff007e}]]>
              </svg:style>
              <g id="XMLID_3_">
                <g id="XMLID_76_">
                  <path
                    id="XMLID_93_"
                    d="M56.8 172.4c2.5 3.3 3.7 7.3 3.7 12.2 0 6.8-2.2 12-6.7 15.7-4.5 3.7-10.8 5.5-19 5.5H3.6v-78.1h30.2c8 0 14.1 1.8 18.4 5.3 4.3 3.5 6.5 8.6 6.5 15.2 0 4.2-1.1 7.9-3.2 10.9-2.2 3-5.2 5.2-9.1 6.7 4.4 1.1 7.9 3.3 10.4 6.6zm-46.5-9.3h22.5c6.3 0 11-1.3 14.3-3.8 3.2-2.6 4.8-6.3 4.8-11.2 0-9.7-6.4-14.6-19.1-14.6H10.3v29.6zm38.6 33.2c3.1-2.5 4.7-6.3 4.7-11.4 0-10.5-6.4-15.7-19.2-15.7H10.3V200h24.1c6.6 0 11.4-1.3 14.5-3.7z"
                  ></path>
                  <path
                    id="XMLID_90_"
                    d="M148.8 205.8h-7l-9.8-26c-1.2-3.5-3-5.9-5.2-7.1-2.2-1.2-5.4-1.8-9.5-1.8H95.5v34.9h-6.8v-78.1h30.2c8.1 0 14.4 1.8 18.9 5.5 4.5 3.7 6.7 9 6.7 15.9 0 5-1.3 9.3-4 12.7-2.7 3.4-6.5 5.9-11.5 7.2 2.3.4 4.2 1.3 5.8 2.9 1.6 1.5 3 3.8 4.1 6.8l9.9 27.1zM118.4 165c12.8 0 19.2-5.3 19.2-16 0-10.3-6.4-15.5-19.2-15.5h-23V165h23z"
                  ></path>
                  <path
                    id="XMLID_88_"
                    d="M175.2 205.8v-78.1h48.2v5.8h-41.5v29.6h39.3v6h-39.3V200h41.5v5.8h-48.2z"
                  ></path>
                  <path
                    id="XMLID_85_"
                    d="M308.5 205.8l-8.9-20.8h-42.1l-9 20.8h-7l34-78.1h6.1l33.9 78.1h-7zM260 179h37l-18.4-43.1L260 179z"
                  ></path>
                  <path
                    id="XMLID_83_"
                    d="M397.4 205.8h-9.2l-42-37.4v37.4h-6.8v-78.1h6.8v36.2l39.9-36.2h9L353 165.9l44.4 39.9z"
                  ></path>
                  <path
                    id="XMLID_81_"
                    d="M418.3 205.8v-78.1h6.8v78.1h-6.8z"
                  ></path>
                  <path
                    id="XMLID_79_"
                    d="M511.1 127.7h6.4v78.1h-5.3l-49.6-66.6v66.6h-6.4v-78.1h5.4l49.5 66.6v-66.6z"
                  ></path>
                  <path
                    id="XMLID_77_"
                    d="M609 165.8v35.7c-2.9 1.5-6.8 2.8-11.7 3.8-5 1-10 1.5-15 1.5-7.6 0-14.2-1.6-19.6-4.8-5.5-3.2-9.7-7.8-12.6-13.8-2.9-6-4.3-13.2-4.3-21.5s1.4-15.4 4.3-21.4c2.9-6 7-10.6 12.5-13.7 5.4-3.1 11.8-4.7 19.2-4.7 11 0 19.9 3.4 26.5 10.2l-2.9 5.4c-3.7-3.4-7.5-5.8-11.2-7.3-3.8-1.5-8-2.2-12.6-2.2-9.2 0-16.3 2.9-21.3 8.8s-7.5 14.2-7.5 25c0 11 2.5 19.5 7.6 25.3 5.1 5.9 12.4 8.8 22 8.8 7.4 0 14.2-1.2 20.5-3.6v-25.6h-20.6v-5.8H609z"
                  ></path>
                </g>
                <path
                  id="XMLID_73_"
                  class="st0"
                  d="M793.7 326.7c-88.9 0-161.2-72.3-161.2-161.2S704.8 4.3 793.7 4.3s161.2 72.3 161.2 161.2-72.3 161.2-161.2 161.2zm0-310.4c-82.3 0-149.2 66.9-149.2 149.2s66.9 149.2 149.2 149.2 149.2-66.9 149.2-149.2-67-149.2-149.2-149.2z"
                ></path>
                <g id="XMLID_70_">
                  <path
                    id="XMLID_72_"
                    class="st0"
                    d="M863.5 101.2c-16.7-17.7-40.4-28.8-66.6-28.8-26.3 0-49.9 11.1-66.6 28.8h133.2z"
                  ></path>
                  <path
                    id="XMLID_71_"
                    class="st0"
                    d="M733.2 229.8c16.5 16 38.9 25.8 63.7 25.8 24.8 0 47.2-9.8 63.7-25.8H733.2z"
                  ></path>
                </g>
                <path
                  id="XMLID_68_"
                  class="st1"
                  d="M719.3 206.7v-34.4h-37.5v34.4H670V126h11.8v34.5h37.5V126h11.8v80.7h-11.8z"
                ></path>
                <path
                  id="XMLID_66_"
                  class="st1"
                  d="M760.6 206.7V126h11.8v80.7h-11.8z"
                ></path>
                <path
                  id="XMLID_64_"
                  class="st1"
                  d="M831.1 137.8v68.9h-11.8v-68.9h-24.6V126h61.1v11.8h-24.7z"
                ></path>
                <path
                  id="XMLID_61_"
                  class="st1"
                  d="M912.1 147.6c-.4-3.1-1.6-5.7-3.5-7.7s-4.4-3.1-7.4-3.1c-3.1 0-5.8 1.1-8 3.3-2.3 2.2-3.4 4.8-3.4 7.9 0 4.8 2.9 8.5 8.8 11.3l16.2 7.4c5.9 4 8.8 10.1 8.8 18.1 0 6.4-2.3 11.8-6.9 16.2-4.6 4.5-10 6.7-16.4 6.7-5.7 0-10.6-1.8-14.7-5.4-4.2-3.7-6.8-8.3-7.9-14l11.5-3.1c.5 3.1 1.6 5.6 3.5 7.6 2 2.2 4.6 3.2 7.6 3.2 3.1 0 5.8-1.1 8-3.3 2.3-2.2 3.4-4.8 3.4-7.9 0-4.7-2.9-8.4-8.8-11.1-5.5-2.5-9.9-4.6-13.3-6.3s-5.9-3.1-7.3-4.1c-2.9-2-4.3-7.1-4.3-15.2 0-6.4 2.3-11.8 6.9-16.2 4.7-4.5 10.1-6.7 16.5-6.7 5.7 0 10.6 1.8 14.7 5.5s6.7 8.3 7.8 14l-11.8 2.9z"
                ></path>
                <g id="XMLID_4_">
                  <path
                    id="XMLID_137_"
                    class="st0"
                    d="M917.2 4.3c-8.6 0-15.6 7-15.6 15.6s7 15.6 15.6 15.6 15.6-7 15.6-15.6-7-15.6-15.6-15.6zm0 28.9c-7.2 0-12.7-5.8-12.7-13.3 0-7.5 5.4-13.3 12.7-13.3 7.1 0 12.6 5.8 12.6 13.3 0 7.5-5.4 13.3-12.6 13.3z"
                  ></path>
                  <path
                    id="XMLID_138_"
                    class="st0"
                    d="M919.8 21c2.9 0 4.7-2.1 4.7-5.1 0-3.5-2.1-5.1-6.3-5.1h-7V29h3v-8h2.5l5.1 8.1h3.2l-5.2-8.1zm-5.6-2.4v-5.5h3.5c1.9 0 3.8.6 3.8 2.7 0 2.4-1.5 2.9-3.8 2.9h-3.5z"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h2 class="downloadAppNow-text">
            <span style="color: #f3225d">Download</span> App!
          </h2>
          <div
            class="download-inner-wrap text-center"
          >
            <a
              href="https://itunes.apple.com/us/app/breaking-hits/id1410157066?mt=8"
              ><img
                src="../assets/image/d-appstore-logo.png"
                alt="download-app-store"
                width="123px"
                title="Download for iPhone!"
            /></a>
            <a
              href="https://play.google.com/store/apps/details?id=com.bhreactproject"
              ><img
                src="../assets/image/d-googleplay.png"
                alt="download-google-play"
                width="130px"
                title="Download for Android!"
            /></a>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6" id="live-section">
          <div class="contentRight-inner text-center">
            <h2
            >
              Breaking Hits App Turns Up the Volume on Amplified Authentic
              Opinions in the Music Business
            </h2>
            <a
              target="_blank"
              href="https://www.send2press.com/wire/breaking-hits-app-turns-up-the-volume-on-amplified-authentic-opinions-in-the-music-business/"
              ><img
                src="../assets/image/the-weeknd-live-sept-29-2018-billboard-1548-compressed.jpg"
            /></a>
          </div>
        </div>
      </div>
    </div>
    <div id="girl-with-phone">
      <img src="../assets/image/girl-with-phone.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DownloadApp",
};
</script>

<style scoped>
#DownloadApp {
  background-color: #fff;
  background-image: url("../assets/image/circle-pinklarge.png");
  background-repeat: no-repeat;
  background-size: 372px 372px;
  background-position: -6% 148%;
}

#DownloadApp::before {
  content: "";
  background-image: url("../assets/image/circle-pinksmall.png");
  width: 191px;
  height: 191px;
  position: absolute;
  right: 120px;
  background-repeat: no-repeat;
}

#downloadAppContent {
  padding-top: 50px;
}

#bh-logo {
  z-index: 9;
  min-height: 300px;
}

#girl-with-phone {
  margin-top: -264px;
  margin-left: -2px;
}

#girl-with-phone img {
  max-width: 500px;
}

.breakinghits-svgLogo {
  width: 408px;
  margin: auto;
}

h2.downloadAppNow-text {
  background-color: #ffffffb3;
  padding: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  width: max-content;
  margin: 20px auto;
}

#live-section {
  z-index: 9;
}

.contentRight-inner h2{
  font-size: 20px;
  font-weight: 500;
  font-family: inherit;
  line-height: 35px;
  padding: 0 31px 0 31px;
}

.contentRight-inner img {
  padding: 10px;
  max-width: 330px;
}

.download-inner-wrap img {
  padding: 2px !important;
}
</style>