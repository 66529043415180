<template>
  <div class="btn-default">
    <button type="button" @click="btnClick" class="btn btn-primary">
      {{ btnName !== "" ? btnName : "Default" }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonDefault",
  props: {
    btnName: String,
  },
  methods: {
    btnClick() {
      this.$emit("defaultClick");
    },
  },
};
</script>

<style scoped>
button {
  background-color: #f3225d;
  border-color: #f3225d;
}

button:hover {
  background-color: #f3225d;
  border-color: #f3225d;
}

button:focus {
  background-color: #f3225d;
  border-color: #f3225d;
}

.btn-check:focus + .btn,
.btn:focus {
  box-shadow: 0 0 0 0 #f3225d04;
}
</style>