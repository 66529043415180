<template>
  <div id="artist-account">
    <div class="row">
      <div class="col-md-12">
        <p>
          Artist Account have the ability to share original songs to be reviewed
          and rated to get valuble feedback and reports from fans and critics.
        </p>
        <form>
          <div class="row" id="size-inputs">
            <div class="col-sm-6">
              <label for="inputPassword5" class="form-label">Width</label>
              <input
                type="number"
                id="width"
                class="form-control"
                aria-describedby="width"
                :value="width"
                @input="getFrameSize($event, 'width')"
              />
            </div>
            <div class="col-sm-6">
              <label for="inputPassword5" class="form-label">Height</label>
              <input
                type="number"
                id="height"
                class="form-control"
                aria-describedby="height"
                :value="height"
                @input="getFrameSize($event, 'height')"
              />
            </div>
          </div>
          <div class="mb-3">
            <textarea
              class="form-control"
              id="createEmbed"
              rows="3"
              ref="createEmbed"
              v-on:focus="$event.target.select()"
              readonly
              :value="value"
            ></textarea>
          </div>
          <div>
            <button class="btn btn-danger" id="submit-btn" type="button" @click="copyFrameCode">
              COPY CODE
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateEmbeded",
  data() {
    return {
      width: 200,
      height: 500,
      value: '',
    };
  },
  methods: {
    getFrameSize(event, type) {
      switch (type) {
        case "width":
          this.width === ""
            ? (this.width = 200)
            : (this.width = event.target.value);
          break;
        case "height":
          this.height === ""
            ? (this.height = 200)
            : (this.height = event.target.value);
          break;
      }
      this.value = this.generateFrame();
    },
    generateFrame() {
        const frameStr = 
        `<div class="bh-embed" style="text-align: center;">
    <div class="bh-embed-inner-wrap">
        <a href="#">
            <iframe src="https://www.breakinghits.com/johnny-paid-270760644c7d4d46e" style="border: 1px solid #232322;" width="${this.width}" height="${this.height}" />
        </a>
    </div>
</div>`; 

      return frameStr
    },
    copyFrameCode() {
        this.$refs.createEmbed.focus();
        document.execCommand('copy');
    }
  },
  mounted() {
    this.value = this.generateFrame();
  },
};
</script>

<style scoped>
#artist-account {
  background-color: #ffffff;
  padding: 0 0 5% 0;
}

#submit-btn {
  float: right;
}

form {
  padding: 0 10px 30px 10px;
}

#createEmbed {
  min-height: 150px;
}

textarea {
  font-size: 12px;
}

#size-inputs input {
  max-width: 200px;
  margin: 0 0 10px 0;
}
</style>