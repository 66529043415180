<template>
  <div id="amplified">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-4 col-xs-6 col-sm-4" id="mobile-signup-section">
          <img
            class="img-fluid text-center"
            src="../assets/image/mobile-signup-1192021.png"
            alt=""
          />
        </div>
        <div
          class="col-md-4 col-sx-6 col-sm-4 text-section"
          id="text-content-section"
        >
          <div class="text-center login-section">
            <h2>BREAKING HITS</h2>
            <h3>Amplified Authentic Opinions.</h3>
            <p>
              For Amplified Authentic Opinions from real fans, vetted
              influencers &amp; verified music industry pros. Bringing Authentic
              Intel to the artists &amp; labels. It is not counting a voice. On
              Breaking Hits; Your voice and your opinion is accounted for.
            </p>
            <a href="#" role="button" class="btn btn-light">JOIN</a>
            <div id="login-link">
              <a href="#">Already a member?</a>
            </div>
          </div>
        </div>
        <div class="white-overlap">
          <canvas id="myCanvas">
            Your browser does not support the HTML5 canvas tag.</canvas
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AmplifiedSection",
  data() {
    return {
      canvasKey: 0,
      maxBarHeight: 80, //30;
      minBarHeight: 15, //15
      barPadding: 1, //space between bars
      barWidth: 0.5, //width of each bar
      totalBars: 440, //total bars to create
      barColor: "#FF6187",
      canvas: null,
      ctx: null,
      bar: [],
      fps: 30,
      indexShrink: 0,
    };
  },
  mounted() {
    this.canvas = document.getElementById("myCanvas");
    this.ctx = this.canvas.getContext("2d");
    this.InitBars();
  },
  methods: {
    InitBars() {
      var barHeight = 0;
      var x = 0;
      var y = 0;
      for (var i = 0; i < this.totalBars; i++) {
        barHeight = this.RandomizeHeight();
        x = i + i + this.barPadding;
        y = this.ComputeYAxis(barHeight);
        this.bar.push({
          height: barHeight,
          buffHeight: barHeight,
          x: x,
          y: y,
        });
        this.ShrinkBar(i);
      }
    },
    ShrinkBar(index) {
      if (this.bar[index].height > this.minBarHeight) {
        this.ctx.fillStyle = this.barColor;
        this.ctx.clearRect(
          this.bar[index].x,
          this.bar[index].y,
          this.barWidth,
          this.bar[index].height
        );
        this.bar[index].height--;
        this.bar[index].y = this.ComputeYAxis(this.bar[index].height);
        this.ctx.fillRect(
          this.bar[index].x,
          this.bar[index].y,
          this.barWidth,
          this.bar[index].height
        );
        requestAnimationFrame(() => {
          this.ShrinkBar(index);
        });
      } else {
        var newHeight = this.RandomizeHeight();
        while (newHeight == this.minBarHeight) {
          newHeight = this.RandomizeHeight();
        }
        this.bar[index].buffHeight = newHeight;
        this.GrowBar(index);
      }
    },
    GrowBar(index) {
      if (this.bar[index].height < this.bar[index].buffHeight) {
        this.ctx.fillStyle = this.barColor;
        this.ctx.clearRect(
          this.bar[index].x,
          this.bar[index].y,
          this.barWidth,
          this.bar[index].height
        );
        this.bar[index].height++;
        this.bar[index].y = this.ComputeYAxis(this.bar[index].height);
        this.ctx.fillRect(
          this.bar[index].x,
          this.bar[index].y,
          this.barWidth,
          this.bar[index].height
        );
        requestAnimationFrame(() => {
          this.GrowBar(index);
        });
      } else {
        this.ShrinkBar(index); //shrink it again
      }
    },
    RandomizeHeight() {
      return Math.floor(Math.random() * this.maxBarHeight + this.minBarHeight);
    },
    ComputeYAxis(height) {
      return Math.round((this.maxBarHeight - height) / 2);
    },
  },
};
</script>

<style scoped>
#amplified {
  min-height: 400px;
  background-image: url("../assets/image/circle-signup.png");
  background-repeat: no-repeat;
  background-size: 650px;
  background-position: 217px 21px;
  padding-top: 50px;
}
p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}
.btn-light {
  color: #f3225d;
  font-weight: bold;
  padding: 8px;
  z-index: 99;
}
#login-link a {
  color: #fff;
  text-decoration: none;
}
.btn-light:hover {
  background-color: #bd1243 !important;
  color: #fff !important;
  border-color: #bd1243 !important;
}
.text-section {
  color: #fff;
}
.white-overlap {
  height: 300px;
  margin-top: -463px;
  z-index: 9;
}
#mobile-signup-section {
  z-index: 99;
}
#text-content-section {
  padding-top: 90px;
}
#text-content-section h2 {
  font-weight: bolder;
  font-size: 38px;
}
.container-fluid {
  padding: 0 !important;
}

canvas {
width: 100%;
margin: auto;
height: 515px;
opacity: .8;
}

.login-section {
  z-index: 99;
  position: relative;
}
</style> 