export default class ValidateForm {
  #validationResult = {};
  #firstName = "";
  #lastName = "";
  #email = "";
  #password = "";
  #userName = "";
  #page = {};
  #elCounter = 0;

  constructor(formData) {
    this.#firstName = formData.firstName;
    this.#lastName = formData.lastName;
    this.#email = formData.email;
    this.#password = formData.password;
    this.#page = formData.page;
    this.#userName = formData.userName;
  }

  checkFormEntry() {
    console.log(this.#userName);
    this.#elCounter = 0;

    if (this.#firstName !== undefined) {
      if (this.#firstName.length === 0) {
        this.#validationResult.firstName = {
          success: false,
          message: "First name is required.",
        };
      } else {
        this.#validationResult.firstName = { success: true };
        this.#elCounter++;
      }
    }

    if (this.#lastName !== undefined) {
      if (this.#lastName.length === 0) {
        this.#validationResult.lastName = {
          success: false,
          message: "Last name is required.",
        };
      } else {
        this.#validationResult.lastName = { success: true };
        this.#elCounter++;
      }
    }

    if (this.#email !== undefined) {
      if (this.#email === 0) {
        this.#validationResult.email = {
          success: false,
          message: "Email is required.",
        };
      } else {
        this.#validationResult.email = { checkEmail: this.#checkEmail() };
      }
    }
    
    if (this.#password !== undefined) {
      if (this.#password.length === 0) {
        this.#validationResult.password = {
          success: false,
          message: "Last name is required.",
        };
      } else {
        this.#validationResult.password = { success: true };
        this.#elCounter++;
      }
    }

    if (this.#userName !== undefined) {
      if (this.#userName.length === 0) {
        this.#validationResult.userName = {
          success: false,
          message: "User name is required.",
        };
      } else {
        this.#validationResult.userName = { success: true };
        this.#elCounter++;
      }
    }
  }

  #checkEmail() {
    const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;

    if (emailPattern.test(this.#email)) {
      this.#elCounter++;
      return { success: true };
    } else {
      return {
        success: false,
        message: "Email is invalid",
      };
    }
  }

  #checkPageFormValidity() {
    console.log(this.#page.inputNum);
    console.log(this.#elCounter);
    
    return this.#page.inputNum == this.#elCounter;
  }

  result() {
    console.log(this.#validationResult);
    return {
      valMessage: this.#validationResult,
      valid: this.#checkPageFormValidity(),
    };
  }
}
