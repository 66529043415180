<template>
  <footer class="blog-footer text-center">
    <div class="footer-section">
      <cite
        >"For you are more than a number, a like or a vote. You are a
        voice."</cite
      >
      <br />
      <small>© 2021 Breaking Hits, Inc. All Rights Reserved.</small>
      <p><a href="/privacy-policy">Privacy Policy</a></p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>

<style scoped>
footer {
  min-height: 138px;
  background-color: /*#222222*/ #090511;
  color: #fff;
  padding: 20px;
}

.footer-section {
    margin-top: 10px;
}
</style>