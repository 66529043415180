<template>
  <div id="community">
    <main class="container">
      <div class="row text-center">
        <div class="col-md-12" id="title">
          <DropdownButton
            :title="title"
            :btnName="btnName"
            :dropDownData="tempDropdown"
            @btnOption="btnOption"
          />
        </div>
      </div>
      <div class="row g-5" id="community-content">
        <div class="col-md-8">
          <div
            class="card"
            v-for="(avatar, index) in this.$store.getters[
              'data/getCommunityData'
            ]"
            :key="index"
          >
            <div class="card-body avatar">
              <AvatarSection
                :imgUrl="avatar.imgUrl"
                :artistName="avatar.artistName"
                :verified="avatar.verified"
                :countryUrl="avatar.countryUrl"
                :date="avatar.date"
              />
              <MusicPLayer :artistName="avatar.artistName" />
              <div class="rater">
                <RaterButton />
              </div>
              <div class="social-buttons">
                <SocialButtons v-on:socialAction="socialAction($event)" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="position-sticky">
            <div class="p-4 mb-3 bg-light rounded" id="sxsw-section">
              <a href="https://www.breakinghits.com/bhsxsw2022" target="_blank"
                ><img class="img-fluid rounded" src="../../assets/image/sxsw.png"
              /></a>
            </div>
            <div class="card text-center mb-3">
              <div class="card-header">Leaderboard</div>
              <div class="card-body">
                <div class="avatar-list">
                  <AvatarList />
                </div>
              </div>
              <div class="card-footer text-muted">See More</div>
            </div>
            <div class="card text-center mb-3">
              <div class="card-header">Discover artist</div>
              <div class="card-body">
                <div class="artist-list">
                  <ArtistList />
                </div>
              </div>
              <span><a href="#"></a>See more artist</span>
            </div>
            <div class="card text-center mb-3">
              <div class="card-body">
                <h5>Are you an artist?</h5>
                <ButtonDefault
                  :btnName="defaultBtnName"
                  @defaultClick="defaultClick"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import DropdownButton from "@/components/subcomponents/DropdownButton.vue";
import ButtonDefault from "@/components/subcomponents/DefaultButton.vue";
import AvatarSection from "@/components/subcomponents/Avatar.vue";
import MusicPLayer from "@/components/subcomponents/MusicPLayer.vue";
import RaterButton from "@/components/subcomponents/Rater.vue";
import SocialButtons from "@/components/subcomponents/SocialButtons.vue";
import AvatarList from "@/components/subcomponents/AvatarList.vue";
import ArtistList from "@/components/subcomponents/ArtistList.vue";

export default {
  name: "CommunitySection",
  components: {
    DropdownButton,
    ButtonDefault,
    AvatarSection,
    MusicPLayer,
    RaterButton,
    SocialButtons,
    AvatarList,
    ArtistList
  },
  data() {
    return {
      title: "Community",
      btnName: "Featured",
      defaultBtnName: "Request Artist Account",
      tempDropdown: [
        "Featured",
        "Pop",
        "Dance",
        "EDM",
        "Rock",
        "Coutry",
        "Folk",
        "Hip-Hop",
        "Rap",
        "R&B",
        "Funk/Soul",
        "Underground",
        "Latin",
        "World",
        "Reggae",
        "DJ Mix",
        "K-Pop",
        "Electronic",
        "Orchestral",
        "Newage",
      ],
    };
  },
  mounted() {
    this.getCommunityData();

    !this.$store.getters['ui/getDarkDropDown'] ? this.$store.commit('ui/setDarkDropDown') : '';
  },
  methods: {
    btnOption(genre) {
      console.log(genre);
    },

    defaultClick() {
      console.log("Clicked at community..");
    },

    // temporary community data
    getCommunityData() {
      console.log(this.$store.getters["data/getCommunityData"]);
    },

    // social actions
    socialAction(e) {
      console.log(e);
    },
  },
};
</script>

<style scoped>
#community {
  background-color: #f8f9fa;
}

#community-content {
  padding: 20px 150px 20px 150px;
}

#sxsw-section {
  padding: 0px !important;
}

.card-footer,
.card-header {
  background-color: #fff;
}

.avatar {
  padding: 0px;
}

.card {
  margin-bottom: 5px;
}

@media only screen and (min-width: 320px) {
  #community {
    padding: 0 5px 0 5px;
  }

  #title {
    margin-top: 71px;
  }

  #community-content {
    padding: 20px 2px 20px 2px;
  }

  .container {
    padding-right: var(--bs-gutter-x, 0.2rem);
    padding-left: var(--bs-gutter-x, 0.2rem);
  }

  .position-sticky {
    margin-top: -33px;
  }
}

.rater {
  margin-top: 10px;
  padding: 10px;
}

.social-buttons {
  padding: 10px;
}
@media only screen and (min-width: 320px) {
  .position-sticky {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    padding-right: var(--bs-gutter-x, 10rem);
    padding-left: var(--bs-gutter-x, 10rem);
  }
}
</style>