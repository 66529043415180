<template>
  <div id="search-artist">
    <div class="row text-center">
      <div class="search-container">
        <div class="col-md-12" id="title">
          <form class="d-flex">
            <input
              class="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <span class="btn-artist"
              ><ButtonDefault
                :btnName="'Search'"
                @defaultClick="defaultClick('search')"
            /></span>
          </form>
        </div>
        <div class="col-md-12" id="button-section">
          <span class="btn-artist"
            ><ButtonDefault
              :btnName="'All Members'"
              @defaultClick="defaultClick('member')"
          /></span>
          <span class="btn-artist"
            ><ButtonDefault
              :btnName="'Artist'"
              @defaultClick="defaultClick('artist')"
          /></span>
        </div>
      </div>
      <div id="gallery-section">
        <GallerySection />
      </div>
    </div>
  </div>
</template>

<script>
import GallerySection from "@/components/Gallery.vue";
import ButtonDefault from "@/components/subcomponents/DefaultButton.vue";
export default {
  name: "SearchArtist",
  components: {
    GallerySection,
    ButtonDefault,
  },
  data() {
    return {
      title: "Discover Trending",
    };
  },
  methods: {
    defaultClick(searchType) {
      console.log(searchType);
    },

    hideGenreViews() {
      if (this.$store.getters["ui/getShowGenreViews"])
        this.$store.commit("ui/setShowGenreViews");
    },
  },
  mounted() {
    // show dashboard custom  nav
    this.hideGenreViews();
  },
};
</script>

<style scoped>
#search-artist {
  background-color: #fff;
}

#title {
  margin-top: 80px;
  padding: 10px;
}

#gallery-section {
  margin-top: -39px;
}

#button-section {
  display: flex;
  padding: 5px;
}

.btn-artist {
  margin-left: 5px;
}

.search-container {
  padding: 0 250px 0 250px;
}

@media only screen and (min-width: 320px) {
  #gallery-section {
    margin-top: -10px;
  }
}
</style>