<template>
  <div id="artist-account">
    <div class="row">
      <div class="col-md-12">
        <p>
          Artist Account have the ability to share original songs to be reviewed
          and rated to get valuble feedback and reports from fans and critics.
        </p>
        <form>
          <div class="mb-3">
            <label for="curentPassword" class="form-label"
              >Current Password</label
            >
            <input
              type="password"
              class="form-control"
              id="curentPassword"
              placeholder="Current password"
            />
          </div>
          <div class="mb-3">
            <label for="newPassword" class="form-label"
              >New Password</label
            >
            <input
              type="password"
              class="form-control"
              id="newPassword"
              placeholder="New password"
            />
          </div>
          <div class="mb-3">
            <label for="confirmPassword" class="form-label"
              >Confirm Password</label
            >
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              placeholder="Confirm password"
            />
          </div>
          <div class="row">
            <div class="col-sm-12">
              <button class="btn btn-danger" id="submit-btn" type="button">
                SUBMIT
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangePassword",
};
</script>

<style scoped>
#artist-account {
  background-color: #ffffff;
  padding: 0 0 5% 0;
}

#submit-btn {
  float: right;
}

form {
  padding: 0 10px 30px 10px;
}

input {
    max-width: 300px;
}
</style>