<template>
  <div id="opinion-section">
    <h1 class="text-center">What's Your Opinion?</h1>
    <Carousel :settings="settings" :breakpoints="breakpoints">
      <Slide v-for="slide in 10" :key="slide">
        <div class="carousel__item">
          <div class="card rounded">
            <div id="card-top-img" class="text-center">
              <img
                src="../assets/image/kg2cl-7600-1644779115-original.jpg"
                class="card-img-top rounded-circle"
                alt="..."
              />
            </div>
            <div class="card-body text-center">
              <h5><a href="#">Shanice <img src="../assets/image/verified-badge-02.png" alt=""></a></h5>
              <div class="player-box">
                <img
                  src="../assets/image/bm7us-8419.41514339-1642161311-medium.jpg"
                  alt=""
                />
                <p class="song-title">Every Woman Dream...<br> <span class="song-artist">Shanice</span></p>
                <button class="btn btn-warning"><span id="play-icon"><font-awesome-icon :icon="['fas', 'play']" /></span></button>
              </div>
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "CardSlider",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      settings: {
        itemsToShow: .5,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3.5,
          snapAlign: "start",
        },

        1440: {
          itemsToShow: 5,
          snapAlign: "start",
        }
      },
    };
  },
};
</script>

<style scoped>
#opinion-section {
  background-color: #eee;
  min-height: 100px;
  padding: 35px;
}

.carousel {
  background-color: #eee;
}

.carousel__item {
  min-height: 200px;
  width: 88%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel ol li {
  margin: -5px;
}

.carousel__prev,
.carousel__next {
  box-sizing: content-box;
}

.card {
  max-height: 350px;
  min-width: 250px;
  border-radius: 10px !important;
  padding-top: 30px;
}

.card a {
  text-decoration: none;
  color: #F3225D;
}

.card-body {
  padding: 8px;
}

.card-body h5 img {
  max-width: 20px;
}

#card-top-img {
  max-width: 155px;
  padding: 4px;
  margin: auto;
}

.player-box {
  min-height: 50px;
  background-color: black;
  border-radius: 5px;
  margin-top: 50px;
}

.player-box img {
  max-width: 50px;
  float: left;
  padding: 5px;
}

.player-box button {
    color: #fff;
    background-color: #F3225D !important;
    border-color: #f3225d;
    border-radius: 50px;
    margin-top: 10px;
    padding: 0px;
    width: 31px;
    height: 30px;
}

.song-title {
 float: left;
 color: white;
 font-size: 14px;
}

.song-artist {
  float: left;
  font-size: 10px;
}

#play-icon {
  margin-top: -5px;
}
</style>