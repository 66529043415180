<template>
  <Multiselect
    v-if="optionType === 'language' || 'country'"
    v-model="value"
    label="name"
    :options="options"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        <img class="character-label-icon" :src="value.icon" /> {{ value.name }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      <img class="character-option-icon" :src="option.icon" /> {{ option.name }}
    </template>
  </Multiselect>

  <Multiselect v-else v-model="value" :mode="modeType" :options="options" />
</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  name: "MultiSelectDropdown",
  components: {
    Multiselect,
  },
  props: {
    modeType: String,
    optionType: String,
  },
  data() {
    return {
      value: null,
      options: [
        "Comedy",
        "Pop",
        "Drama",
        "Dance",
        "Thriller",
        "EDM",
        "Action",
        "Rock",
        "SciFi & Anime",
        "Country",
        "Musical & Dance",
        "Folk",
        "Music Videos",
        "Hip-Hop",
        "Rap",
        "Documentary",
        "Trap",
        "Vblog",
        "R&B",
        "Funk/Soul",
        "Reggae",
        "Faith Based",
        "Dj Mix",
        "Producer Tracks",
        "K-Pop",
        "Opinions",
        "Music Network",
        "Electronic",
        "Orchestral",
        "Newage",
      ],
    };
  },
  methods: {
    checkMode() {
      console.log(this.optionType);
      switch (this.optionType) {
        case "country":
          this.options = this.$store.getters["data/getCountryData"];
          break;
        case "state":
          this.options = this.$store.getters["data/getCountryState"];
          break;
        case "city":
          this.options = this.$store.getters["data/getCountryCity"];
          break;
        case "language":
          this.options = this.$store.getters["data/getCountryLanguage"];
          break;
      }
    },
  },
  mounted() {
    this.checkMode();
  },
};
</script>

<style scoped>
@import '@vueform/multiselect/themes/default.css';

img {
    max-width: 30px !important;
    padding: 5px;
}

.multiselect-option {
  white-space: nowrap;
}
</style>