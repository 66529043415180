<template>
  <div id="gallery">
    <div class="gallery-container">
      <div class="image-wrapper">
        <div class="row">
          <div
            v-for="(gallery, index) in galleryData"
            :key="index"
            class="col-4 mt-3"
            id="gallery-content"
          >
            <div class="card">
              <img
                class="card-img-top"
                src="../assets/image/kg2cl-7600-1644779115-original.jpg"
                alt="Card image"
              />
              <div class="card-img-overlay">
                <div v-if="this.$store.getters['ui/getShowGenreViews']">
                  <span id="artist-genre">{{ gallery.genre }}</span>
                  <span id="artist-views"
                    >{{ gallery.views }}
                    <img
                      class="view-icon"
                      src="../assets/image/view-icon.png"
                      alt=""
                  /></span>
                </div>
                <div id="artist-name">
                  {{ gallery.artist }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GallerySection",
  data() {
    return {
      galleryData: [],
      fakeGalleryData: [
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
        {
          img: "https://breakinghits.s3.amazonaws.com/profile/ibvaq-473-1542862789-large.jpg",
          genre: "Funk/Soul",
          views: "15453",
          artist: "Bindi Liebowitz",
        },
      ],
    };
  },
  mounted() {
    this.setGallery();
  },
  methods: {
    setGallery() {
      // change fake data to api data
      this.$store.commit("data/setGallery", this.fakeGalleryData);
      this.galleryData = this.$store.getters["data/getGallery"];
    },
  },
};
</script>

<style scoped>
.card-img-overlay {
  color: #fff;
}

#artist-genre {
  float: left;
}

#artist-views {
  float: right;
}

@media only screen and (min-width: 320px) {
  .card-img-overlay {
    font-size: 10px;
    margin: 10px 5px 0 5px;
  }

  .card-img-overlay img {
    width: 12px;
  }

  .gallery-container {
    padding: 2px;
  }

  #artist-name {
    margin-top: 75px;
  }

  .mt-3 {
    margin-top: 0rem !important;
  }

  .card {
    margin-bottom: 0px;
  }

  .card-img-overlay {
    padding: 0px;
  }

  .row {
    --bs-gutter-x: 0;
  }
}

@media only screen and (min-width: 425px) {
  .card-img-overlay {
    font-size: 13px;
    margin: 10px 5px 0 5px;
  }

  #artist-name {
    margin-top: 90px;
  }
}

@media only screen and (min-width: 768px) {
  .row {
    --bs-gutter-x: 1.2rem;
    --bs-gutter-y: -2.5rem;
    padding: 18px;
  }

  .card {
    margin-bottom: 17px;
  }

  .card-img-overlay {
    font-size: 15px;
    margin: 12px 5px 0 5px;
  }

  #artist-name {
    margin-top: 165px;
  }
}

@media only screen and (min-width: 1024px) {
  .image-wrapper {
    margin: 0 15% 0 15%;
  }
}

@media only screen and (min-width: 1440px) {
  .card-img-overlay {
    font-size: 15px;
    margin: 30px 5px 0 5px;
    padding: 11px;
  }

  #artist-name {
    margin-top: 200px;
  }
}
</style>