<template>
  <div v-if="this.$store.getters['ui/getHideFooter']" class="icon-bar d-md-none">
    <a href="#home"><font-awesome-icon :icon="['fas', 'list-ul']" /></a>
    <a href="#news"><font-awesome-icon :icon="['fas', 'search']" /></a>
    <a href="#contact"><font-awesome-icon :icon="['fas', 'plus-circle']" /></a>
    <a href="#about"><font-awesome-icon :icon="['fas', 'comment-dots']" /></a>
  </div>
</template>

<script>
export default {
  name: "FooterIconNav",
  
};
</script>

<style scoped>
.icon-bar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #222222;
  border-top: 1px solid #363636;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-bar a {
  width: 25%;
  text-align: center;
  padding: 10px 0;
  transition: all 0.3s ease;
  color: #999;
  font-size: 23px;
}

.icon-bar a:active {
  color: #fff;
}

.icon-bar a:hover {
  background-color: #000;
}
</style>