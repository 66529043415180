<template>
  <div class="container-fluid">
    <div id="settings">
      <div class="row" id="settings-content">
        <div class="col-md-3">
          <div class="left-list">
            <ul class="list-group">
              <li class="list-group-item list-group-item-action">
                <router-link to="/settings/basic">Manage Profile</router-link>
              </li>
              <li class="list-group-item list-group-item-action">
                <router-link to="/settings/request-artist-account"
                  >Request Artist Account</router-link
                >
              </li>
              <li class="list-group-item list-group-item-action">
                <router-link to="/settings/invite">Invite Friends</router-link>
              </li>
              <li class="list-group-item list-group-item-action">
                <router-link to="/settings/change-password"
                  >Change Password</router-link
                >
              </li>
              <li class="list-group-item list-group-item-action">
                <router-link to="create-embeded">Create Embeded</router-link>
              </li>
              <li class="list-group-item list-group-item-action">
                Block Listed
              </li>
              <li class="list-group-item list-group-item-action">Logout</li>
            </ul>
          </div>
        </div>
        <div class="col-md-9" id="settings-view">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProfileSetting",
};
</script>

<style scoped>
.container-fluid {
  background-color: #f8f9fa;
}

#settings {
  background-color: #f8f9fa;
  margin: 0 19% 0 19%;
  padding-bottom: 10%;
}

#settings-content {
  background-color: #ffffff;
}

.left-list {
  padding: 8px;
}

#submit-btn {
  float: right;
}

.list-group-item a {
  text-decoration: none;
  color: #222222;
}

@media only screen and (min-width: 320px) {
  .left-list {
    display: none;
  }
  #settings {
    margin: 0%;
  }
  #settings-content {
    padding: 26% 0%;
  }
}

@media only screen and (min-width: 768px) {
  .left-list {
    display: block;
  }
  #settings {
    margin: 0 2% 0 2%;
  }
  #settings-content {
    padding: 15% 0%;
  }
}
</style>