<template>
  <div class="container mt-5" id="avatar-list-section">
    <div
      class="height-100 row d-flex justify-content-center align-items-center"
    >
      <div class="col-md-12">
        <div
          class="p-3 bg-white rounded"
          v-for="(list, index) in this.$store.getters['data/getLeaderBoardList']"
          :key="index"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-row align-items-center">
              <div class="position-relative img-list">
                <img
                  :src="list.imgUrl"
                  width="80"
                  class="rounded-circle img-fluid"
                />
              </div>
              <div class="avatar-detail">
                <h5 class="mb-0">
                  {{ '#' + list.rank + ' ' + list.artistName }} <br />
                  {{ list.address !== '' ? list.address : '' }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvatarList",
};
</script>

<style scoped>

.img-list img {
    max-width: 55px;
}

.mt-5 {
  margin-top: 0rem !important;
}

.p-3 {
  padding: 5px !important;
}

#avatar-list-section {
  padding: 0px;
}

#avatar-list-section h5 {
  font-size: 14px;
}

.avatar-detail {
  margin-left: 10px;
}

.card-body {
  padding: 0px !important;
}
</style>