<template>
  <div class="messenger">
    <div class="container messenger-wrapper">
      <h3>Messenger</h3>
      <div class="messenger-content shadow">
        <div class="row">
          <div class="col-md-3">
            <div class="left-wrapper">
              <div id="custom-search-input">
                <div class="input-group">
                  <input
                    type="text"
                    class="search-query form-control"
                    placeholder="Conversation"
                  />
                  <button class="btn btn-danger" type="button">
                    <font-awesome-icon :icon="['fas', 'search']" />
                  </button>
                </div>
              </div>
              <div class="conversation-section">
                <span id="comments"
                  ><font-awesome-icon :icon="['fas', 'comments']"
                /></span>
                All Conversations
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="right-wrapper">
              <div class="new-message-head">
                <button type="button" class="btn btn-light float-end">
                  <span id="comments"
                    ><font-awesome-icon :icon="['fas', 'chevron-down']"
                  /></span>
                </button>
              </div>
              <div class="chat-area"></div>
              <div class="create-message">
                <textarea
                  class="form-control"
                  id="message"
                  rows="3"
                  placeholder="Type message"
                ></textarea>
                <div class="send-message row">
                  <div class="col-6" id="upload-image">
                    <div class="mb-3">
                      <input
                        @change="handleImage"
                        class="form-control"
                        type="file"
                        id="formFile"
                        accept="image/*"
                        ref="imgUpload"
                        hidden
                      />

                      <div class="upload-img">
                        <div class="img-select" v-if="imageObject !== ''">
                          <button
                            type="submit"
                            class="close remove-img"
                            @click="removeImage"
                          >
                            <span id="img-select-text">&times;</span>
                          </button>
                          <img :src="imageObject" alt="" class="rounded" />
                        </div>
                        <label
                          id="upload-photo"
                          for="formFile"
                          v-if="imageObject === ''"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            style="width: 18px"
                          >
                            <path
                              d="M87.1.2H12.9C5.9.2.2 5.9.2 12.9V87c0 7 5.7 12.7 12.7 12.7H87c7 0 12.7-5.7 12.7-12.7V12.9C99.8 5.9 94.1.2 87.1.2zm-81 12.7C6.1 9.1 9.2 6 13 6h74.1c3.8 0 6.9 3.1 6.9 6.9v41.7L80 37.5c-1.4-1.7-3.4-2.7-5.6-2.9-2.2-.1-4.3.7-5.9 2.3L41.6 63.7l-10-8.3c-2.9-2.4-7.1-2.4-10 .1L6.1 68.7V12.9z"
                            ></path>
                            <path
                              d="M45.7 26.6c0-7.8-6.3-14.1-14.1-14.1s-14.1 6.3-14.1 14.1 6.3 14.1 14.1 14.1 14.1-6.3 14.1-14.1zm-22.4 0c0-4.6 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3c0 4.6-3.7 8.3-8.3 8.3s-8.3-3.7-8.3-8.3z"
                            ></path>
                          </svg>
                          <br />
                          ATTACH IMAGE
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <button class="btn btn-primary btn-send-message">
                      SEND
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessengerSection",
  data() {
    return {
      imageObject: "",
    };
  },
  methods: {
    handleImage(e) {
      const getImage = e.target.files[0];

      this.generateBase64Img(getImage);
    },
    generateBase64Img(image) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageObject = e.target.result;
        console.log(this.imageObject);
      };
      reader.readAsDataURL(image);
    },
    removeImage() {
      this.$refs.imgUpload.value = "";
      this.imageObject = "";
    },
  },
};
</script>

<style scoped>
.messenger {
  background-color: #f8f9fa;
  min-height: 82vh;
}

h3 {
  margin-top: 25px;
  color: #f3225d !important;
}

.btn-danger {
  background-color: #fff;
  border-color: #fff;
  color: black;
}

input {
  border-color: #fff;
  padding: 5px;
}

.messenger-wrapper {
  max-width: 1000px;
  padding: 70px 50px;
}

.conversation-section,
#custom-search-input {
  padding: 10px;
}

.chat-area,
.new-message-head,
#custom-search-input,
.col-md-9 {
  border-color: white;
  border-style: solid;
  border-width: 0.5px;
}

.chat-area,
.new-message-head,
#custom-search-input {
  border-bottom-color: #c3bdbd;
}

.new-message-head {
  min-height: 35px;
}

.btn-light {
  max-height: 20px;
  font-size: 12px;
  color: #abaeb1;
}

.btn-light,
.btn-light:hover {
  background-color: #fff !important;
  border-color: #fff !important;
}

input:focus,
textarea:focus,
.btn:focus {
  border: 1px solid #c3bdbd;
  outline: none !important;
  box-shadow: none !important;
}

input:focus {
  border: 1px solid #fff;
}

.col-md-9 {
  border-left-color: #c3bdbd;
}

.col-md-9,
.col-md-3 {
  background-color: #fff;
  padding: 0px;
}

.chat-area {
  min-height: 340px;
}

.create-message {
  padding: 10px;
}

svg {
  fill: #f3225d;
}
img {
  max-width: 50px;
}

.remove-img {
  margin-left: 42px;
  margin-top: -8px;
  position: absolute;
  border-radius: 10px;
  max-height: 15px;
  max-width: 10px;
}

#img-select-text[data-v-365371e4] {
  margin-top: -4.9px;
  margin-left: -4px;
  display: block;
  font-weight: bold;
}

.upload-img {
  font-size: 12px;
}

.send-message {
  margin-top: 20px;
}

.btn-send-message {
  min-width: 150px;
  min-height: 45px;
  background-color: #f3225d;
  border-color: #f3225d;
  float: right;
}

#upload-photo {
  text-align: center;
}
</style>