<template>
  <Datepicker v-model="date"></Datepicker>
</template>

<script>
export default {
  data() {
    return {
      date: null,
    };
  },
};
</script>