<template>
  <button type="button" class="btn btn-google" @click="googleLogin">
    <span class="btn-icon"
      ><font-awesome-icon :icon="['fab', 'google-plus']"
    /></span>
    Login with Google
  </button>
</template>

<script>
export default {
  name: "googleLogin",
  methods: {
    async googleLogin() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        this.user = googleUser.getBasicProfile();

        // console.log("googleUser", googleUser);
        // this.user = googleUser.getBasicProfile().getEmail();
        // console.log("getId", this.user);
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        // console.log("getAuthResponse", googleUser.getAuthResponse());
        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.instance.currentUser.get().getAuthResponse()
        // );

        this.$emit("gLogin", this.user);
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
  },
};
</script>

<style scoped>
</style>