<template>
  <div id="artist-account">
    <div class="row">
      <h6>Basic Profile: (Critic)</h6>
      <div class="col-md-3">
        <div id="manage-profile" :key="key">
          <img
            class="rounded"
            :src="
              imageObject === ''
                ? 'https://lh5.googleusercontent.com/-ez5Dm1jxeHE/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmxWE1pRWKrSUxTphIR6eCj7b3bgA/s50/photo.jpg'
                : imageObject
            "
            alt=""
          />
        </div>
        <div class="mb-3" id="upload-image">
          <input
            @change="handleImage"
            class="form-control"
            type="file"
            id="formFile"
            accept="image/*"
            hidden
          />
          <label id="upload-photo" for="formFile">Update Photo</label>
        </div>
      </div>
      <div class="col-md-9" id="update-profile">
        <h6>Personal Invite URL</h6>
        <input
          type="text"
          id="personalUrl"
          ref="personalUrl"
          :value="urlValue"
          v-on:focus="$event.target.select()"
          readonly
        />
        <div>
          <button
            class="btn btn-danger"
            id="copyUrl"
            type="button"
            @click="copyUrl"
            :title="$t('test.sample')"
          >
            Copy your pesonal invite link
          </button>
          <div
            class="form-text text-muted"
            style="margin-top: 5px; font-size: 8pt"
          >
            Share this URL to invite friends to your profile and build fans.
          </div>
          <div class="row">
            <div class="col-md-7">
              <div class="mb-3 mt-3">
                <label for="changUrl" class="form-label"
                  >Create your Personal Invite URL</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="changeUrl"
                  name="personalUrl"
                  v-model="changeUrl"
                />
                <div
                  class="form-text text-muted"
                  style="margin-top: 5px; font-size: 8pt"
                >
                  Note: Special character is not allowed.
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <!-- <div class="mb-3 mt-3">URL is available</div> -->
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="firstName" class="form-label">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  name="firstName"
                  v-model="firstName"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="lastName" class="form-label">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  name="lastName"
                  v-model="lastName"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea5">Bio</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea5"
                rows="3"
              ></textarea>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="website" class="form-label">Website</label>
                <input
                  type="text"
                  class="form-control"
                  id="website"
                  name="website"
                  v-model="website"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlTextarea5">Favorite Genres</label>
                <div class="multi-dropdown">
                  <MultiSelectDropdown :modeType="'tags'" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="birth" class="form-label">Date of Birth</label>
                <DatePickerEl />
              </div>
            </div>
            <div class="col-md-6">
              <label for="gender" class="form-label">Gender</label>
              <div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="male"
                  />
                  <label class="form-check-label" for="exampleRadios1">
                    Male
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios2"
                    value="female"
                  />
                  <label class="form-check-label" for="exampleRadios2">
                    Female
                  </label>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="exampleFormControlTextarea5">Country</label>
                <div class="multi-dropdown">
                  <MultiSelectDropdown :optionType="'country'" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="birth" class="form-label">State</label>
                <div class="multi-dropdown dropdown-cust">
                  <MultiSelectDropdown :optionType="'state'" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="birth" class="form-label">City</label>
                <div class="multi-dropdown dropdown-cust">
                  <MultiSelectDropdown :optionType="'city'" />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="mobile" class="form-label">Mobile</label>
                <input
                  type="text"
                  class="form-control"
                  id="mobile"
                  name="mobile"
                  v-model="mobile"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label for="language" class="form-label">Language</label>
                <div class="multi-dropdown dropdown-cust" id="language">
                  <MultiSelectDropdown :optionType="'language'" />
                </div>
              </div>
            </div>

            <div class="col-md-6 new-section">
              <h6>Profile Display</h6>
            </div>
            <div class="col-md-6 new-section">
              <div class="form-check form-switch">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                />
              </div>
            </div>

            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fas', 'ticket']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Ticket Shows"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'spotify']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Spotify Artist URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'itunes']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="itunes URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'deezer']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Deezer URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'facebook']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Facebook URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'twitter']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Twitter URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'linkedin']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Linkedin URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1"
                  ><font-awesome-icon :icon="['fab', 'wikipedia-w']"
                /></span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Wikepedia URL"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div class="col-md-12">
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">@</span>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MultiSelectDropdown from "@/components/subcomponents/MultiSelect.vue";
import DatePickerEl from "@/components/subcomponents/DatePickerComp.vue";

export default {
  name: "ManageProfile",
  components: {
    MultiSelectDropdown,
    DatePickerEl
  },
  data() {
    return {
      imageObject: "",
      key: 0,
      urlValue: "www.breakinghits.com/johnny-paid-270760644c7d4d46e",
    };
  },
  methods: {
    handleImage(e) {
      const getImage = e.target.files[0];

      this.generateBase64Img(getImage);
    },
    generateBase64Img(image) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageObject = e.target.result;
      };
      reader.readAsDataURL(image);
    },
    copyUrl() {
      console.log("test");
      this.$refs.personalUrl.focus();
      document.execCommand("copy");
    },
  },
  mounted() {
    console.log(process.env.I18N_FALLBACK_LOCAL);
  },
};
</script>

<style scoped>
#artist-account {
  background-color: #ffffff;
  padding: 0 0 5% 0;
}

#submit-btn {
  float: right;
}

form {
  padding: 0 10px 30px 10px;
}

#manage-profile IMG {
  max-width: 80px;
  max-height: 50px;
}

#upload-photo {
  padding: 5px;
  display: inline-block;
  border-style: solid;
  border-color: black;
  border-radius: 5px;
  border-width: thin;
  margin-top: 8px;
}

#personalUrl {
  border: none;
  width: 100%;
}

#personalUrl:focus {
  outline: #ffffff;
}

.multi-dropdown {
  min-height: 38px !important;
  margin-top: 7px !important;
}

.dropdown-cust {
  margin-top: -2px !important;
}

#language {
  max-width: 245px;
}

.new-section {
  margin-top: 30px;
}
</style>