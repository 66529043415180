<template>
  <div class="home">
    <JumbotronSection />
    <AmplifiedSection />
    <CardSlider />
    <DownloadApp />
  </div>
</template>

<script>
import AmplifiedSection from "@/components/Amplified.vue";
import JumbotronSection from "@/components/Jombotron.vue";
import CardSlider from "@/components/CardSlider.vue";
import DownloadApp from "@/components/DownloadApp.vue";

export default {
  name: "HomeView",
  components: {
    JumbotronSection,
    CardSlider,
    AmplifiedSection,
    DownloadApp,
  },
  mounted() {
    this.hideLanguageDropdown();
  },
  methods: {
    hideLanguageDropdown() {
      (this.$store.getters['ui/getShowLangOption']) ? this.$store.commit('ui/setShowLangOption') : '';
    }
  },
};
</script>
