<template>
  <div id="artist-account">
    <div class="row">
      <div class="col-md-12">
        <p>
          Artist Account have the ability to share original songs to be reviewed
          and rated to get valuble feedback and reports from fans and critics.
        </p>
        <form>
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">Email(s)</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
            />
            <div
              class="form-text text-muted"
              style="margin-top: 5px; font-size: 8pt"
            >
              You can input a multiple email using comma(,).
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6">
              <button class="btn btn-danger" id="submit-btn" type="button" :title="$t('test.tool-tips.botton-submit')">
                SEND INVITATION
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InviteFriends",
};
</script>

<style scoped>
#artist-account {
  background-color: #ffffff;
  padding: 0 0 5% 0;
}

#submit-btn {
  float: right;
}

form {
  padding: 0 10px 30px 10px;
}
</style>