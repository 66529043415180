const user = {
  namespaced: true,
  state: {
    isLogin: false,
    userResponseData: {},
    userId: "",
  },
  mutations: {
    setIsLogin(state, isLoggedIn) {
      state.isLogin = isLoggedIn; //!state.isLogin;
    },
    setUserResponseData(state, userData) {
        state.userResponseData = userData;
    },
    setUserId(state, userId) {
      state.userId = userId;
    }
  },
  getters: {
    getIsLogin: (state) => state.isLogin,
    getUserResponseData: (state) => state.userResponseData,
    getUserId: (state) => state.userId
  },
};

export default user;
