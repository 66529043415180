const ui = {
  namespaced: true,
  state: {
    showGenreViews: true,
    darkDropDown: false,
    showLangOption: false,
    showDashboardModal: false,
    dashboardModalCount: 0,
    showFeedbackModal: false,
    hideFooter: false,
    isPageLoading: true
  },
  mutations: {
    setPageLoading(state, isLoading) {
      state.isPageLoading = isLoading;
    },
    setShowGenreViews(state) {
      state.showGenreViews = !state.showGenreViews;
    },
    setDarkDropDown(state) {
      state.darkDropDown = !state.darkDropDown;
    },
    setShowLangOption(state, show) {
      state.showLangOption = typeof show === 'undefined' ? !state.showLangOption : show;
    },
    setShowDashboardModal(state, show) {
      state.showDashboardModal = show;
    },
    setDashboardModalCount(state, count) {
      state.dashboardModalCount = count;
    },
    setShowFeedbackModal(state) {
      state.showFeedbackModal = !state.showFeedbackModal;
    },
    setHideFooter(state) {
      state.hideFooter = !state.hideFooter;
    }
  },
  getters: {
    getPageLoading: (state) => state.isPageLoading,
    getShowGenreViews: (state) => state.showGenreViews,
    getDarkDropDown: (state) => state.darkDropDown,
    getShowLangOption: (state) => state.showLangOption,
    getShowDashboardModal: (state) => state.showDashboardModal,
    getDashboardModalCount: (state) => state.dashboardModalCount,
    getShowFeedbackModal: (state) => state.showFeedbackModal,
    getHideFooter: (state) => state.hideFooter
  },
};

export default ui;
