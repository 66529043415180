<template>
  <div class="container-fluid report-page">
    <div class="row">
      <div class="mobile-nav">
        <nav class="navbar navbar-expand-sm bg-light navbar-light">
          <div class="container-fluid">
            <h5>Spotlights</h5>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <font-awesome-icon :icon="['fas', 'align-justify']" />
            </button>
            <div class="collapse navbar-collapse" id="collapsibleNavbar">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">Link</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <nav
        id="sidebarMenu"
        class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
      >
        <div class="position-sticky pt-3">
          <ul class="nav flex-column">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">
                <font-awesome-icon :icon="['fas', 'list-ol']" />
                <span data-feather="home"></span>
                Dashboard
              </a>
            </li>
          </ul>
        </div>
      </nav>

      <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <div class="align-items-center right-content-section">
          <div class="container mt-3 right-content row">
            <div class="col-12 cust-card-body">
              <div class="card-body">Ratings</div>
            </div>
            <div class="col-12 cust-card-body">
              <div class="card-body">Spotlights</div>
            </div>
            <div class="table-section">
              <table class="table table-borderless tbl-content">
                <thead>
                  <tr>
                    <th scope="col">Music</th>
                    <th scope="col">Rating</th>
                    <th scope="col">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="tr-content">
                    <td><a href="#">Photos</a></td>
                    <td>
                      <div class="mid-td">
                        <span class="td-value">0.0 / 0</span>
                      </div>
                    </td>
                    <td>
                      <a href=""
                        ><font-awesome-icon :icon="['fas', 'commenting']" /> 0
                        Comment(s)</a
                      >
                    </td>
                  </tr>
                  <div class="col spacer"></div>
                  <tr class="tr-content">
                    <td><a href="#">Potential</a></td>
                    <td>
                      <div class="mid-td">
                        <span class="td-value">0.0 / 0</span>
                      </div>
                    </td>
                    <td>
                      <a href=""
                        ><font-awesome-icon :icon="['fas', 'commenting']" /> 0
                        Comment(s)</a
                      >
                    </td>
                  </tr>
                  <div class="col spacer"></div>
                  <tr class="tr-content">
                    <td><a href="#">IN THE CALI SUN</a></td>
                    <td>
                      <div class="mid-td">
                        <span class="td-value">0.0 / 0</span>
                      </div>
                    </td>
                    <td>
                      <a href=""
                        ><font-awesome-icon :icon="['fas', 'commenting']" /> 0
                        Comment(s)</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="tbl-cards">
                <div class="row">
                  <div class="tbl-cards-content">
                    <div class="col-sm-12 tbl-cards-cust">
                      <a href="#">Photos</a>
                    </div>
                    <div class="col-sm-12 tbl-cards-cust">
                      <div class="mid-td">
                        <span class="td-value">0.0 / 0</span>
                      </div>
                    </div>
                    <div class="col-sm-12 tbl-cards-cust">
                      <a href=""
                        ><font-awesome-icon :icon="['fas', 'commenting']" /> 0
                        Comment(s)</a
                      >
                    </div>
                  </div>
                  <div class="tbl-cards-content">
                    <div class="col-sm-12 tbl-cards-cust">
                      <a href="#">Photos</a>
                    </div>
                    <div class="col-sm-12 tbl-cards-cust">
                      <div class="mid-td">
                        <span class="td-value">0.0 / 0</span>
                      </div>
                    </div>
                    <div class="col-sm-12 tbl-cards-cust">
                      <a href=""
                        ><font-awesome-icon :icon="['fas', 'commenting']" /> 0
                        Comment(s)</a
                      >
                    </div>
                  </div>
                  <div class="tbl-cards-content">
                    <div class="col-sm-12 tbl-cards-cust">
                      <a href="#">Photos</a>
                    </div>
                    <div class="col-sm-12 tbl-cards-cust">
                      <div class="mid-td">
                        <span class="td-value">0.0 / 0</span>
                      </div>
                    </div>
                    <div class="col-sm-12 tbl-cards-cust">
                      <a href=""
                        ><font-awesome-icon :icon="['fas', 'commenting']" /> 0
                        Comment(s)</a
                      >
                    </div>
                    <Teleport to="body">
                      <CommonModal
                        :show="this.$store.getters['ui/getShowFeedbackModal']"
                      />
                    </Teleport>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import CommonModal from "@/components/modals/CommonModal.vue";

export default {
  name: "ReportPage",
  components: {
    CommonModal,
  },
  mounted() {
    !this.$store.getters["ui/getHideFooter"]
      ? this.$store.commit("ui/setHideFooter")
      : "";
  },
  beforeUnmount() {
    this.$store.commit("ui/setHideFooter");
  },
};
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  /* rtl:raw:
  right: 0;
  */
  bottom: 0;
  /* rtl:remove */
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 48px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #fff;
  background-color: #080808;
  padding: 15px;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.pt-3 {
  padding-top: 8px !important;
}

.report-page {
  background-color: #f5f5f5;
  height: 100vh;
}

.container {
  margin: auto;
}

.cust-card {
  margin-top: 30px;
  font-size: 1.5rem;
}

.cust-card-body {
  color: #f3225d;
  background-color: #fff;
  margin-bottom: 30px;
  font-size: 1.5rem;
  border-radius: 5px;
}

.table {
  table-layout: fixed;
}

.table > :not(caption) > * > * {
  padding: 13px 17px;
}

.mid-td {
  background-color: #f5f5f5;
  border-radius: 15px;
  padding: 0 5px;
  color: #fff;
  min-height: 29px;
}

.td-value {
  margin-left: 10px;
}

.tr-content {
  background-color: #fff;
}

.spacer {
  min-height: 9px;
  background-color: #f5f5f5;
}

tr td:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tbl-cards a,
tr td a {
  text-decoration: none;
  color: #337ab7;
}

tr td a:focus,
tr td a:hover {
  color: #23527c;
}

.tbl-cards {
  background-color: #f5f5f5;
  padding: 9px;
}

.tbl-cards-content {
  margin-top: 8px;
  background-color: #fff;
  padding: 12px;
}

.right-content-section {
  margin-top: 50px;
}

.right-content {
  padding: 5px;
}

.card-body {
  padding: 0.5rem 0.5rem;
}

.table-section {
  padding: 0px;
}

.tbl-cards-cust {
  padding: 5px;
}

.bg-light {
  background-color: #fff !important;
}

.navbar-toggler {
  font-size: 15px;
}

@media only screen and (min-width: 320px) {
  .cust-card-body,
  .cust-card,
  .tbl-content {
    display: none;
  }

  .mobile-nav,
  .tbl-cards {
    display: block;
  }

  .row > * {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .right-content-section {
    margin-top: -32px;
  }

  .navbar-light .navbar-toggler {
    border-color: #fff;
  }

  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .mobile-nav {
    margin-top: 59px;
  }

  .mobile-nav h5 {
    color: #f3225d;
    font-size: 13px;
    font-weight: 400;
  }
}

@media only screen and (min-width: 768px) {
  .cust-card,
  .tbl-content {
    display: table;
  }

  .mobile-nav,
  .tbl-cards {
    display: none;
  }

  .row {
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  .cust-card-body {
    display: block;
  }

  main {
    margin-top: 98px;
  }
}
</style>