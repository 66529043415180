<template>
  <div id="social">
    <div class="row social-wrapper">
      <div class="col-md-12 d-flex">
        <div class="social-content">
          <span class="save">
            <div class="circle-wrap" @click="socialAction('save')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-193 195 612 612"
              >
                <path
                  d="M239.6 489.9h-94.7V340.7c0-6.2-5-11.8-11.8-11.8-6.2 0-11.8 5-11.8 11.8V501c0 6.2 5 11.8 11.8 11.8h106.5c6.2 0 11.8-5 11.8-11.8-.7-6.2-5.6-11.1-11.8-11.1zM217.3 741.8c-27.2 9.3-55.7 14.2-84.2 14.2-123.2 0-228.4-87.9-250.7-208.6l-.6-2.5h-23.5l.6 3.7c22.9 133.7 138 230.3 273.6 230.3 28.5 0 56.3-4.3 82.9-13l1.9-.6 4.3-26-4.3 2.5zM259.4 746.7c-3.7 0-7.4-1.2-10.5-3.1-5.6-3.7-8-10.5-6.8-17.3l8-45.2c0-.6 0-.6-.6-1.2l-32.8-32.2c-5-5-6.2-11.8-4.3-18 1.9-6.2 7.4-11.1 14.2-11.8l45.2-6.8c.6 0 .6-.6 1.2-.6l20.4-41.5c3.1-6.2 8.7-9.9 15.5-9.9 6.8 0 12.4 3.7 15.5 9.9l20.4 41.5c0 .6.6.6 1.2.6l45.2 6.8c6.8 1.2 11.8 5.6 14.2 11.8 1.9 6.2.6 13-4.3 18l-32.8 32.2c-.6.6-.6.6-.6 1.2l8 45.2c1.2 6.8-1.2 13-6.8 17.3-5.6 3.7-12.4 4.3-18.6 1.2l-40.9-21.7h-1.2l-40.9 21.7c-2.3 1.3-4.8 1.9-7.9 1.9zm49.5-171.4c-.6 0-1.2.6-1.2.6l-20.4 41.5c-2.5 5-7.4 8.7-13 9.3l-45.2 6.8s-.6 0-1.2 1.2c0 .6 0 1.2.6 1.2l32.8 32.2c4.3 3.7 6.2 9.9 5 15.5l-8 45.2s0 .6.6 1.2 1.2 0 1.2 0l40.9-21.7c5-2.5 11.1-2.5 16.1 0L358 730s.6.6 1.2 0 .6-1.2.6-1.2l-8-45.2c-1.2-5.6.6-11.1 5-15.5l32.8-32.2s.6-.6.6-1.2-.6-.6-1.2-1.2l-45.2-6.8c-5.6-.6-10.5-4.3-13-9.3l-20.4-41.5c-.3 0-.9-.6-1.5-.6z"
                ></path>
                <path
                  d="M133.1 223.1c-97.8 0-188.2 52-238.3 135.6l-65-64.4c-2.5-2.5-6.8-3.7-9.9-1.9-3.7 1.2-5.6 5-5.6 8.7v162.2c0 2.5 1.2 5 2.5 6.8 1.9 1.9 4.3 2.5 6.8 2.5h164c3.7 0 7.4-2.5 8.7-5.6 1.2-3.7.6-7.4-1.9-9.9L-87.2 376C-42 296.2 42.2 246.6 134.4 246.6c140.5 0 255 114.5 255 255 0 29.1-5 58.2-14.9 85.4l-.6 3.1 3.1 1.2h1.2l18 2.5.6-2.5c9.9-29.1 14.9-59.4 14.9-90.4-.7-153.4-125.1-277.8-278.6-277.8zM-167.7 454V323.4l131.8 130-131.8.6z"
                ></path>
              </svg>
            </div>
          </span>
          <div class="text-save"><p>SAVE</p></div>
        </div>
        <div class="social-content">
          <span class="spotlight">
            <div class="circle-wrap spotlight-inner" @click="socialAction('spotlight')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-193 195 612 612"
              >
                <path
                  d="M334.4 710.9L-2.2 203.5c-2.5-3.8-6.6-6.3-11.1-6.8-4.5-.6-9 .9-12.4 4l-92.6 84.7c-3.8 3.5-5.7 8.7-4.9 13.8l65.8 433.3c-.5 2.2-.7 4.3-.7 6.1 0 8.4 3.7 20.8 21.3 32.3C-26 778-11 784.2 7.8 789.5c37 10.3 85.9 16 137.8 16 51.9 0 100.8-5.7 137.8-16 18.8-5.2 33.8-11.5 44.6-18.6 17.6-11.6 21.3-24 21.3-32.3 0-7.3-2.8-17.6-14.9-27.7zM-18.1 235.8L281.2 687c-36.7-9.9-84.8-15.4-135.6-15.4-51.9 0-100.8 5.7-137.8 16-15.4 4.3-28.2 9.2-38.3 14.8l-60.7-399.8 73.1-66.8zm294 526.4c-34.6 9.6-80.8 14.9-130.2 14.9-49.4 0-95.6-5.3-130.2-14.9-37.1-10.3-45.2-21.5-45.2-23.7 0-2.2 8-13.4 45.2-23.7 34.6-9.6 80.8-14.9 130.2-14.9 49.4 0 95.6 5.3 130.2 14.9 37.1 10.3 45.2 21.5 45.2 23.7-.1 2.2-8.1 13.4-45.2 23.7z"
                ></path>
              </svg>
            </div>
          </span>
          <div>SPOTLIGHT</div>
        </div>
        <div class="social-content">
          <span class="fan">
            <div class="circle-wrap" @click="socialAction('fan')">
              <svg
                id="svg_not_folowed"
                style="display: none"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7.5 1A6.51 6.51 0 0 0 1 7.5 6.51 6.51 0 0 0 7.5 14 6.51 6.51 0 0 0 14 7.5 6.51 6.51 0 0 0 7.5 1zm0 1C10.543 2 13 4.457 13 7.5S10.543 13 7.5 13A5.493 5.493 0 0 1 2 7.5C2 4.457 4.457 2 7.5 2zM7 4v3H4v1h3v3h1V8h3V7H8V4z"
                ></path>
              </svg>
              <svg
                id="svg_following"
                style="display: inline-block"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
              >
                <path
                  d="M15 .2C6.8.2.2 6.8.2 15S6.8 29.8 15 29.8 29.8 23.2 29.8 15 23.2.2 15 .2zm7.4 16.3h-5.9v5.9h-3v-5.9H7.6v-3h5.9V7.6h3v5.9h5.9v3z"
                ></path>
              </svg>
            </div>
          </span>
          <div class="text-fan"><p>FAN</p></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialButtons",
  methods: {
    socialAction(type) {
        this.$emit('socialAction', type);
    },
  },
};
</script>

<style scoped>
.d-flex {
  justify-content: center;
}

svg {
  color: #fff;
  fill: #fff;
  padding: 9px;
}

.spotlight {
  display: block;
  border-radius: 50px;
  background-color: #fff;
  border: 2px solid #f3225d;
  height: 65px;
  width: 65px;
}

.save svg {
  color: #f3225d;
  fill: #f3225d;
  height: 50px;
  margin: 10px 15px 0 0;
}

.fan svg {
  color: #f3225d;
  fill: #f3225d;
  height: 50px;
  margin: 10px 0 0 15px;
}

.text-save p {
  margin: 5px 0 0 12px;
}

.text-fan p {
  margin: 5px 0 0 27px;
}

.spotlight-inner {
  background-color: #f3225d;
  margin: 7px;
  border-radius: 50px;
}

.social-content {
  font-size: 12px;
}
</style>