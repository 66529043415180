import { feathersClient, makeServicePlugin, BaseModel } from "@/api/feathers-client"; 

class UserUrlInformation extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'UserUrlInformation'
  // Define default properties here
  static instanceDefaults() {
    return {}
  }
}

const servicePath = '/api/user-uri-info'
const servicePlugin = makeServicePlugin({
  Model: UserUrlInformation,
  service: feathersClient.service(servicePath),
  servicePath: 'user-url'
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [ async (context) => {
      let isVerified = false;

      if(context.result.result.permissions.length !== 0) {
        isVerified = context.result.result.permissions[0].verified === 1;
      }

      context.result = {
        _id: context.result.id,
        id: context.result.result.url,
        isVerified,
        ...context.result.result
      };
      return context;
    } ],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin